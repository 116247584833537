import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import * as yup from "yup";
import { Form, Grid, Container } from "semantic-ui-react";
import { BackgroundImage, Button } from "components";
import {
  CurrentShop,
  FrontendConfigs,
  setError,
  retrieveOrCreateReturnOrder,
  fetchAndAddOrder,
  resetStore,
  setP2PReturns,
} from "actions";
import styles from "./CustomerInfo.module.css";
import { StoreState } from "reducers";
import { useTranslation } from "react-i18next";
import {
  getOrderEmailPhoneLabel,
  getOrderEmailPhonePlaceholder,
  getOrderIdLabel,
  getOrderIdPlaceholder,
  getRedirectUrl,
  getStartButtonName,
} from "./helpers";
import { fetchClient } from "api/constants";
import { useHistory, useParams } from "react-router-dom";
import { saveCredentialsToStorage } from "helpers/sessionStorage";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { isMobile } from "react-device-detect";

interface CustomerInfoProps {
  currentShop: CurrentShop;
  resetStore: Function;
  retrieveOrCreateReturnOrder: Function;
  fetchAndAddOrder: Function;
  primaryColor: FrontendConfigs["primaryColor"];
}

interface FormValues {
  orderId: string;
  emailOrPhone: string;
}

const _CustomerInfo: React.FC<CustomerInfoProps> = ({
  retrieveOrCreateReturnOrder,
  fetchAndAddOrder,
  resetStore,
}): JSX.Element => {
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const locale = useSelector((state: StoreState) => state.locale);
  const { id: shopId, allowGiftReturns, returnPolicyLink, userInterfaceSetting } = currentShop;
  const { t } = useTranslation(["translation", "orderFlow"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { shopSlug } = useParams<{ shopSlug: string }>();
  const { isEmbedded } = useIsEmbedded();

  const validationSchema = yup.object().shape({
    orderId: yup.string().required(t("orderFlow:customerInfo.errors.orderId")),
    emailOrPhone: yup.string().required(t("orderFlow:customerInfo.errors.emailOrPhone")),
  });

  function handleOrderNameBlur(e: React.FocusEvent<HTMLInputElement>): void {
    // Call backend to cache all info related to an order right after the
    // customer insert the order name and focus away from the order name field on frontend.
    e.target.value = e.target.value.trim();
    const orderName = e.target.value;
    if (orderName.length > 0) {
      fetchClient("/api/v2/customer/cache_ecom_orders", {
        body: { orderName: orderName, filter: { shopId: { eq: shopId } } },
      })
        .then((_response) => {})
        .catch((error) => dispatch(setError(Error(error))));
    }
  }

  async function handleSubmit(values: FormValues) {
    const { orderId, emailOrPhone } = values;
    const isMobileEmbedded = isMobile && isEmbedded;

    resetStore();

    const { nextRoute, returnOrder } = await retrieveOrCreateReturnOrder(orderId, emailOrPhone);
    saveCredentialsToStorage({
      orderNameOrId: orderId,
      emailOrPhone: emailOrPhone,
    });
    dispatch(setP2PReturns(returnOrder.p2pReturns));

    // If it's embedded app on mobile, we don't want to call fetchAndAddOrder. This is because fetchAndAddOrder can be still running when we do the redirect. This can cause Network Error on IOS.
    // The redrect URL will include orderId and emailOrPhone, so in the next step we can always fetch the order.
    if (!isMobileEmbedded) {
      fetchAndAddOrder(returnOrder.id);
    }

    if (returnOrder.status === "draft") {
      isMobileEmbedded
        ? (window.top!.location.href = getRedirectUrl(nextRoute, orderId, emailOrPhone))
        : history.push(nextRoute);
    } else {
      const summaryDetailsRoute = `/${shopSlug}/returns/${returnOrder.id}/details`;

      isMobileEmbedded
        ? (window.top!.location.href = getRedirectUrl(summaryDetailsRoute, orderId, emailOrPhone))
        : history.push(summaryDetailsRoute);
    }
  }

  return (
    <BackgroundImage>
      <div className={styles.container}>
        <div className={styles.content}>
          <Container className={styles.contentContainer} text style={{ width: "40%" }}>
            <Formik
              initialValues={{ orderId: "", emailOrPhone: "" }}
              onSubmit={(values: FormValues) => handleSubmit(values)}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, errors }: FormikProps<FormValues>): JSX.Element => (
                <Form className={styles.form} onSubmit={handleSubmit}>
                  <Grid centered>
                    <Grid.Column mobile={16} tablet={14} computer={12}>
                      <Field name="orderId">
                        {({
                          field,
                          form: { touched, errors, handleChange },
                        }: FieldProps<FormValues["orderId"]>): JSX.Element => (
                          <Form.Field>
                            <label>{getOrderIdLabel(userInterfaceSetting.orderIdLabelTranslations)}</label>
                            <Form.Input
                              {...field}
                              error={touched.orderId && errors.orderId}
                              type="text"
                              placeholder={getOrderIdPlaceholder(userInterfaceSetting.orderIdPlaceholderTranslations)}
                              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                handleChange(e);
                                handleOrderNameBlur(e);
                              }}
                            />
                          </Form.Field>
                        )}
                      </Field>
                      <Field name="emailOrPhone">
                        {({
                          field,
                          form: { touched, errors },
                        }: FieldProps<FormValues["emailOrPhone"]>): JSX.Element => (
                          <Form.Field>
                            <label>
                              {getOrderEmailPhoneLabel(userInterfaceSetting.orderEmailPhoneLabelTranslations)}
                            </label>
                            <Form.Input
                              error={touched.emailOrPhone && errors.emailOrPhone}
                              type="text"
                              placeholder={getOrderEmailPhonePlaceholder(
                                userInterfaceSetting.orderEmailPhonePlaceholderTranslations,
                              )}
                              {...field}
                            />
                          </Form.Field>
                        )}
                      </Field>
                      <Button color="primaryColor" disabled={Object.keys(errors).length !== 0} fluid>
                        {getStartButtonName(userInterfaceSetting.startButtonNameTranslations)}
                      </Button>
                      <div className={styles.termsContainer}>
                        <small>
                          {t("orderFlow:customerInfo.agreement")}{" "}
                          <a href={returnPolicyLink} rel="noopener noreferrer" target="_blank">
                            {t("orderFlow:customerInfo.returnsPolicy")}
                          </a>
                          .
                        </small>
                      </div>

                      {allowGiftReturns && (
                        <Button
                          type="button"
                          className={styles.returningAGiftButton}
                          onClick={() => {
                            const host = process.env.REACT_APP_NEW_CUSTOMER_APP_URL;
                            const redirectUrl = `${host}/${currentShop.shopSlug}/gift_returns/start?locale=${locale.code}`;
                            window.location.href = redirectUrl;
                          }}
                        >
                          {t("orderFlow:customerInfo.returningAGift")}
                        </Button>
                      )}
                    </Grid.Column>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      </div>
    </BackgroundImage>
  );
};

export const CustomerInfo = connect(null, {
  retrieveOrCreateReturnOrder,
  fetchAndAddOrder,
  resetStore,
})(_CustomerInfo);
