import React, { useEffect, useState } from "react";
import { Modal, Image, Header, Icon, Divider } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import { Button, Loading } from "components";
import { NotFound } from "pages";
import { ImageUpload } from "./ImageUpload/ImageUpload";
import { useTranslation } from "react-i18next";
import { useDestroyImage, useImageUpload } from "../hooks";
import { isMobile } from "react-device-detect";
import { AdaptiveModal } from "components/AdaptiveModal";
import { StickyBottomAppBar } from "StickyBottomAppBar";
import { DeleteIcon } from "./DeleteIcon";

export const ImageUploadSummary = () => {
  const history = useHistory();
  const { t } = useTranslation("orderFlow");

  const { productListingId } = useParams();
  const { productListings, isLoading, refetch } = useP2PProductListings({ staleTime: Infinity });
  const productListing = productListings.find((productListing) => productListing.id === Number(productListingId));

  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState<number | "not selected">("not selected");

  const { upload, isLoading: isUploading } = useImageUpload({ onSuccess: refetch });
  const { destroy, isLoading: isDestroying } = useDestroyImage({ onSuccess: refetch });

  const [imagesBeingDestroyed, setImagesBeingDestroyed] = useState<number[]>([]);

  useEffect(() => {
    if (!productListing) {
      return;
    }

    setImagesBeingDestroyed([]);
  }, [productListing]);

  if (isLoading) {
    return (
      <AdaptiveModal>
        <Modal.Content>
          <Loading />
        </Modal.Content>
      </AdaptiveModal>
    );
  }

  if (!productListing) {
    return <NotFound />;
  }

  if (selectedInstructionIndex === -1) {
    return <NotFound />;
  }

  const instructions = productListing.imageUploadRequirement.instructions;

  if (selectedInstructionIndex !== "not selected") {
    const instruction = instructions[selectedInstructionIndex];
    return (
      <ImageUpload
        instructions={instructions}
        selectedInstructionIndex={selectedInstructionIndex}
        isUploading={isUploading}
        isDestroying={isDestroying}
        onImageUploaded={(uploadedImage) => {
          upload({
            imageData: JSON.stringify(uploadedImage),
            productListingId: productListing.id,
            instructionId: instructions[selectedInstructionIndex].id,
          });
        }}
        onImageRemoved={() => {
          destroy({ imageId: instruction.image!.id, productListingId });
        }}
        onBack={() => {
          if (selectedInstructionIndex === 0) {
            history.goBack();
          } else {
            setSelectedInstructionIndex(selectedInstructionIndex - 1);
          }
        }}
        onProceed={() => {
          if (selectedInstructionIndex === instructions.length - 1) {
            setSelectedInstructionIndex("not selected");
          } else {
            const nextUnfulfilledInstructionPosition = instructions
              .slice(selectedInstructionIndex)
              .findIndex((instruction) => !instruction.image);

            if (nextUnfulfilledInstructionPosition === -1) {
              setSelectedInstructionIndex("not selected");
            } else {
              setSelectedInstructionIndex(selectedInstructionIndex + nextUnfulfilledInstructionPosition);
            }
          }
        }}
      />
    );
  }

  return (
    <AdaptiveModal style={{ display: "flex", flexDirection: "column", gap: "16px", padding: 32 }}>
      <header style={{ display: "flex", gap: 8, alignItems: "baseline", flexDirection: isMobile ? "column" : "row" }}>
        {isMobile && (
          <div style={{ padding: 8, cursor: "pointer" }} onClick={history.goBack}>
            <img
              src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/left-arrow.png"
              style={{ height: 14 }}
            />
          </div>
        )}
        <Header style={{ fontSize: "1.8rem", flexGrow: 1, margin: 0 }}>{t("p2pProductListingPage.title")}</Header>
        {!isMobile && (
          <div style={{ padding: 8, cursor: "pointer" }} onClick={history.goBack}>
            <img
              src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/close.png"
              style={{ height: 14 }}
            />
          </div>
        )}
      </header>

      <Divider />

      <p style={{ margin: 0 }}>
        {productListing.productListable.productTitle}
        <br />
        {productListing.productListable.variantTitle}
      </p>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${isMobile ? 2 : 3}, 1fr)`,
          columnGap: isMobile ? 24 : 48,
          rowGap: 16,
        }}
      >
        {instructions.map((instruction, index) => (
          <div key={index} style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
            <div style={{ marginBottom: 8 }}>{`${index + 1}. ${instruction.title}`}</div>
            {instruction.image ? (
              <div style={{ position: "relative" }}>
                {!imagesBeingDestroyed.includes(index) && (
                  <DeleteIcon
                    onClick={() => {
                      setImagesBeingDestroyed([...imagesBeingDestroyed, index]);
                      destroy({ imageId: instruction.image!.id, productListingId });
                    }}
                  />
                )}
                {imagesBeingDestroyed.includes(index) && (
                  <div
                    style={{
                      aspectRatio: "1/1",
                      width: "100%",
                      position: "absolute",
                      zIndex: 10,
                    }}
                  >
                    <Loading />
                  </div>
                )}
                <Image
                  src={instruction.image.url}
                  rounded
                  bordered
                  style={{ width: "100%", aspectRatio: "1/1", objectFit: "cover", zIndex: 0 }}
                  onClick={() => {
                    setSelectedInstructionIndex(index);
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  aspectRatio: "1/1",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8,
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: "0.3125em",
                  color: "rgba(0, 0, 0, 0.4)",
                }}
                onClick={() => {
                  setSelectedInstructionIndex(index);
                }}
              >
                <Icon name="add" />
                {t("p2pProductListingPage.addPhoto")}
              </div>
            )}
          </div>
        ))}
      </div>

      <StickyBottomAppBar>
        <Button
          color="primaryColor"
          fluid
          disabled={isDestroying || instructions.some((instruction) => !instruction.image)}
          onClick={history.goBack}
        >
          {t("common.confirm")}
        </Button>
      </StickyBottomAppBar>
    </AdaptiveModal>
  );
};
