import React, { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components";
import styles from "./Descriptions.module.css";
import { ShippingMethod } from "types";
import { formatPriceString, getShippingMethodInfo } from "./helper";
import { useQueryClient } from "react-query";
import { ModalType } from "pages/ShippingMethodSelector/ShippingMethodSelector";

interface PickupDescriptionProps {
  country: string;
  shippingMethod: ShippingMethod;
  openModal: ModalType;
}

export const PickupDescription: React.FC<PickupDescriptionProps> = ({ country, shippingMethod, openModal }) => {
  const { t } = useTranslation("orderFlow");
  const { requiresPayment, currency, priceCents } = getShippingMethodInfo(shippingMethod);
  const queryClient = useQueryClient();
  const hasDynamicPricing = !shippingMethod.fixedPrice;
  const price = formatPriceString(priceCents, currency);

  function setInitialPriceString() {
    if (hasDynamicPricing) {
      return requiresPayment ? t("rates.varies") : t("rates.free");
    } else {
      return requiresPayment ? price : t("rates.free");
    }
  }
  const [priceString, setPriceString] = useState(() => setInitialPriceString());

  const cashOnPickup = requiresPayment && country === "Vietnam";

  const renderCashOnPickupMessage = (cashOnPickup: boolean): JSX.Element | null => {
    if (cashOnPickup === false) return null;
    return (
      <p>
        Tham khảo giá vận chuyển{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/pick-up-rates.pdf"
        >
          tại đây
        </a>
        .
      </p>
    );
  };

  useEffect(() => {
    // Early return rate varies if it's cashOnPickup as VNCPost don't actually have any rates
    if (cashOnPickup) return setPriceString(t("rates.varies"));

    const pickupFeeQueryState = queryClient.getQueryState<{ cents: number; currencyIso: string }>([
      "pickupFee",
      shippingMethod.id,
    ]);

    if (!pickupFeeQueryState) return;

    const { data: queryData } = pickupFeeQueryState;

    if (!queryData) return;

    switch (pickupFeeQueryState.status) {
      case "loading":
        return setPriceString(t("rates.fetching"));
      case "error":
        return setPriceString(t("rates.fetchingError"));
      case "success":
        const priceString = requiresPayment
          ? formatPriceString(queryData.cents, queryData.currencyIso)
          : t("rates.free");
        return setPriceString(priceString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal === "pickupModal"]);

  return (
    <div className={styles.container}>
      <div style={{ width: 35 }}>
        <Icon name="shipping fast" size="large" />
      </div>
      <div className={styles.textBox}>
        <div className={styles.labelContainer}>
          <Button as="div" color="primaryColor" size="mini">
            Contactless
          </Button>
        </div>
        <div className={styles.titleContainer}>
          <strong>{t("pickUp.header")}</strong>
        </div>
        <p>{t("pickUp.description")}</p>
        {renderCashOnPickupMessage(cashOnPickup)}
      </div>
      <div className={styles.rightTextBox}>
        <strong>{priceString}</strong>
      </div>
    </div>
  );
};
