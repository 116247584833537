import React, { MouseEventHandler } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { StoreState } from "reducers";
import { getFormattedPriceString } from "helpers";
import { Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

interface Props {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ReturnItemValueButton = ({ onClick }: Props) => {
  const displayPrice = useSelector((state: StoreState) => state.formModal.selectedLineItem?.displayPrice);
  const bonusCreditAmountCents = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem.bonusCreditAmountCents,
  );
  const currency = useSelector((state: StoreState) => state.order.currency);
  const { t } = useTranslation("orderFlow");

  const BonusCreditBadge = () => {
    return !!bonusCreditAmountCents ? (
      <Label
        style={{ fontSize: "9px", fontWeight: 500, backgroundColor: "#d5f5dd", color: "#00a737" }}
        size="mini"
        circular
      >
        {getFormattedPriceString(bonusCreditAmountCents, currency)}{" "}
      </Label>
    ) : null;
  };

  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        borderRadius: "20px",
        height: "40px",
        padding: "0 16px 0",
        backgroundColor: "#f5f5f5",
        display: "flex",
        gap: "8px",
        alignItems: "center",
      }}
    >
      <img
        src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/down-arrow.png"
        alt="view return item value"
        style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{
            fontSize: "9px",
            fontWeight: "normal",
            lineHeight: "1.1rem",
          }}
        >
          {t("productSelector.returnItemInformationSidebar.header")}
        </span>
        <span
          style={{
            fontSize: "9px",
            lineHeight: "0.8rem",
          }}
        >
          {getFormattedPriceString(displayPrice!.cents, displayPrice!.currencyIso)} <BonusCreditBadge />
        </span>
      </div>
    </div>
  );
};
