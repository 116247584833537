import React, { ChangeEventHandler, FocusEventHandler, MouseEventHandler, forwardRef } from "react";
import { isMobile } from "react-device-detect";
import styles from "./ExchangeProductSelector.module.css";

interface Props {
  placeholder: string;
  value?: string;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClearClick?: MouseEventHandler<HTMLElement>;
}

export const SearchField = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, value, onFocus, onChange, onClearClick }, ref) => {
    return (
      <div
        className={styles.searchButtonContainer}
        onClick={(e) => e.currentTarget.getElementsByTagName("input")[0].focus()}
      >
        <img
          src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/search-interface-symbol.png"
          alt="search for product to exchange"
          style={{
            height: isMobile ? "12px" : "14px",
            cursor: "pointer",
          }}
        />
        <input placeholder={placeholder} ref={ref} value={value} onChange={onChange} onFocus={onFocus} />
        {value && (
          <img
            src="https://postco360-attachments.sgp1.digitaloceanspaces.com/static/close.png"
            alt="close the sidebar"
            style={{ height: isMobile ? "10px" : "12px", cursor: "pointer" }}
            onClick={onClearClick}
          />
        )}
      </div>
    );
  },
);
