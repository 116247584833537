import { setP2PReturns } from "actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";

export const ReturnTypeTabs = () => {
  const isP2PReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const dispatch = useDispatch();

  const tabs = [
    {
      label: <div>Regular returns</div>,
      onClick: () => dispatch(setP2PReturns(false)),
      selected: !isP2PReturns,
    },
    {
      label: (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4, lineHeight: 1 }}>
          <span>
            <img
              src="https://postco360-attachments.sgp1.cdn.digitaloceanspaces.com/static/P2P/renew_logo_with_text.svg"
              alt="reNEW returns"
              style={{ height: "1.2lh", verticalAlign: "middle" }}
            />{" "}
            returns
          </span>
        </div>
      ),
      onClick: () => dispatch(setP2PReturns(true)),
      selected: isP2PReturns,
    },
  ];
  return (
    <div
      style={{
        borderRadius: "8px",
        backgroundColor: "#F7F7F7",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {tabs.map((tab, index) => (
        <div
          key={index}
          style={{
            border: tab.selected ? "2px solid #e6e6e6" : "",
            backgroundColor: tab.selected ? "white" : "",
            cursor: tab.selected ? "default" : "pointer",
            borderRadius: tab.selected ? "10px" : "",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBlock: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            flex: 1,
          }}
          onClick={tab.onClick}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};
